<template>
    <Transition v-if="isLoading"
                name="spinner">
        <div id="cover-spin"></div>
    </Transition>

    <v-form :name="props.formProps.formName">
        <TabView scrollable
                 style="overflow: visible"
                 :pt="{ panelContainer: { class: 'form-tab-panel' } }">
            <TabPanel :header="__('Basic data')"
                      headerClass="form-tab-header">
                <div class="grid">
                    <FormInputText fieldName="courier_name"
                                   label="Courier name"
                                   size="6"
                                   validation="required"
                                   :isFormDisabled="isFormDisabled"
                                   :isFocus="true"
                                   v-model:field="crud.courier_name" />

                    <FormInputText fieldName="symbol"
                                   label="Symbol"
                                   size="2"
                                   validation="required"
                                   :isFormDisabled="isFormDisabled"
                                   v-model:field="crud.symbol" />

                    <FormRadioButton fieldName="calculation_method"
                                     label="Calculation method"
                                     size="2"
                                     elementStyle="font-size:12px"
                                     :data="select?.calculation_methods"
                                     :isFormDisabled="isFormDisabled"
                                     v-model:field="crud.calculation_method" />

                    <FormInputNumber fieldName="volumetric_weight_factor"
                                     label="Volumetric weight factor"
                                     size="2"
                                     :isFormDisabled="isFormDisabled"
                                     :min="1"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="crud.volumetric_weight_factor" />
                </div>
                <div class="grid">
                    <FormEditor fieldName="description"
                                label="Description"
                                :isFormDisabled="isFormDisabled"
                                v-model:field="crud.description" />
                </div>
            </TabPanel>
            <!--
            <TabPanel :header="__('Surcharges table')"
                      headerClass="form-tab-header">
                <FormRepeaterTable v-model:record="crud.surcharges"
                                   :keys="{ 'surcharge_value': null, 'currency_id': null, 'is_percent': 0, 'is_custom': 0, 'is_size': false, 'size_from': 0, 'size_to': 0, 'surcharge_name': null }"
                                   :disabled="isFormDisabled"
                                   :isFirstRowEmpty="false"
                                   :isCopyButton="true"
                                   repeaterStyle="margin-top:5px;"
                                   name="surcharges"
                                   :title="[__('Value'), __('Currency (net)'), __('Is percent?'), __('Is custom?'), __('Is size?'), __('Height from'), __('Height to'), __('Surcharge name')]">
                    <template #repeat="slotProps">
                        <td :style="slotProps.cellStyle">
                            <FormInputNumber :style="{ width: '150px' }"
                                             fieldName="surcharge_value"
                                             :isFormDisabled="isFormDisabled"
                                             :inRepeater="true"
                                             :minFractionDigits="2"
                                             :maxFractionDigits="2"
                                             v-model:field="slotProps.record.surcharge_value" />
                        </td>
                        <td :style="slotProps.cellStyle">
                            <FormDropdown :style="{ width: '120px' }"
                                          fieldName="currency_id"
                                          showClear
                                          validate="required"
                                          :inRepeater="true"
                                          :isFormDisabled="isFormDisabled"
                                          :options="select?.currencies"
                                          :filtering="false"
                                          v-model:field="slotProps.record.currency_id" />
                        </td>
                        <td :style="slotProps.cellStyle">
                            <FormCheckbox fieldName="is_percent"
                                          :isFormDisabled="isFormDisabled"
                                          :inRepeater="true"
                                          v-model:field="slotProps.record.is_percent" />
                        </td>
                        <td :style="slotProps.cellStyle">
                            <FormCheckbox fieldName="is_custom"
                                          :isFormDisabled="isFormDisabled"
                                          :inRepeater="true"
                                          v-model:field="slotProps.record.is_custom" />
                        </td>
                        <td :style="slotProps.cellStyle">
                            <FormCheckbox fieldName="is_size"
                                          :isFormDisabled="isFormDisabled"
                                          :inRepeater="true"
                                          v-model:field="slotProps.record.is_size" />
                        </td>
                        <td :style="slotProps.cellStyle">
                            <FormInputNumber :style="{ width: '100px' }"
                                             fieldName="size_from"
                                             :isFormDisabled="isFormDisabled"
                                             :inRepeater="true"
                                             :minFractionDigits="0"
                                             :maxFractionDigits="0"
                                             v-model:field="slotProps.record.size_from" />
                        </td>
                        <td :style="slotProps.cellStyle">
                            <FormInputNumber :style="{ width: '100px' }"
                                             fieldName="size_to"
                                             :isFormDisabled="isFormDisabled"
                                             :inRepeater="true"
                                             :minFractionDigits="0"
                                             :maxFractionDigits="0"
                                             v-model:field="slotProps.record.size_to" />
                        </td>
                        <td :style="slotProps.cellStyle">
                            <FormInputText :style="{ width: '350px' }"
                                           fieldName="surcharge_name"
                                           :isFormDisabled="isFormDisabled"
                                           :inRepeater="true"
                                           v-model:field="slotProps.record.surcharge_name" />
                        </td>
                    </template>
</FormRepeaterTable>
</TabPanel>
-->

            <TabPanel :header="__('Surcharges table')"
                      headerClass="form-tab-header">
                <FormRepeaterTable v-model:record="crud.surcharges"
                                   :keys="{ 'surcharge_value': null, 'currency_id': null, 'is_percent': 0, 'is_custom': 0, 'surcharge_name': null }"
                                   :disabled="isFormDisabled"
                                   :isFirstRowEmpty="false"
                                   :isCopyButton="true"
                                   repeaterStyle="margin-top:5px;width:50%"
                                   name="surcharges"
                                   :title="[__('Value'), __('Currency (net)'), __('Is percent?'), __('Is custom?'), __('Surcharge name')]">
                    <template #repeat="slotProps">
                        <td :style="slotProps.cellStyle">
                            <FormInputNumber :style="{ width: '150px' }"
                                             fieldName="surcharge_value"
                                             :isFormDisabled="isFormDisabled"
                                             :inRepeater="true"
                                             :minFractionDigits="2"
                                             :maxFractionDigits="2"
                                             v-model:field="slotProps.record.surcharge_value" />
                        </td>
                        <td :style="slotProps.cellStyle">
                            <FormDropdown :style="{ width: '120px' }"
                                          fieldName="currency_id"
                                          showClear
                                          validation="required"
                                          :inRepeater="true"
                                          :isFormDisabled="isFormDisabled"
                                          :options="select?.currencies"
                                          :filtering="false"
                                          v-model:field="slotProps.record.currency_id" />
                        </td>
                        <td :style="slotProps.cellStyle">
                            <FormCheckbox fieldName="is_percent"
                                          :isFormDisabled="isFormDisabled"
                                          :inRepeater="true"
                                          v-model:field="slotProps.record.is_percent" />
                        </td>
                        <td :style="slotProps.cellStyle">
                            <FormCheckbox fieldName="is_custom"
                                          :isFormDisabled="isFormDisabled"
                                          :inRepeater="true"
                                          v-model:field="slotProps.record.is_custom" />
                        </td>
                        <td :style="slotProps.cellStyle">
                            <FormInputText :style="{ width: '350px' }"
                                           fieldName="surcharge_name"
                                           :isFormDisabled="isFormDisabled"
                                           :inRepeater="true"
                                           v-model:field="slotProps.record.surcharge_name" />
                        </td>
                    </template>
                </FormRepeaterTable>
            </TabPanel>

            <TabPanel :header="__('Changelog')"
                      headerClass="form-tab-header">
                <ChangeLog :log="crud?.log" />
            </TabPanel>
        </TabView>

        <div class="grid">
            <FormButtons :formProps="props.formProps"
                         :sourceForm="crud"
                         :validation="validation"
                         v-model:isFormDisabled="isFormDisabled">
            </FormButtons>
        </div>
    </v-form>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import { validation, initForm } from '@pages/Helper/crud_utils.js'
import FormRepeaterTable from '@crud/FormRepeaterTable.vue'
import FormInputText from '@crud/FormInputText.vue'
import FormInputNumber from '@crud/FormInputNumber.vue'
import FormCheckbox from '@crud/FormCheckbox.vue'
import FormDropdown from '@crud/FormDropdown.vue'
import FormRadioButton from '@crud/FormRadioButton.vue';
import FormEditor from '@crud/FormEditor.vue'
import FormButtons from '@crud/FormButtons.vue'
import ChangeLog from '@crud/ChangeLog.vue'
import { __ } from '@helper/utils.js'

// -----------------------------------------------------

const props = defineProps({
    formProps: Object,
})

const isLoading = ref(true);
const isFormDisabled = ref(true)

const options = ["calculation_methods", "currencies"];
const select = ref(null);
const crud = ref({})

// -----------------------------------------------------

onMounted(async () => {
    await initForm(props, crud, isLoading, options, select)
})

// -----------------------------------------------------

</script>

<style lang="scss" scoped></style>
