<template>
    <v-form>
        <Transition v-if="isLoading"
                    name="spinner">
            <div id="cover-spin"></div>
        </Transition>

        <div class="grid">
            <FormMultiselect fieldName="countries"
                             label="Countries"
                             showClear
                             :options="select?.countries"
                             v-model:field="countries" />
        </div>
        <div class="grid">
            <FormRepeaterTable v-model:record="productpacks"
                               :keys="{ 'productpack_name': null, 'real_weight': 0, 'height': 0, 'width': 0, 'length': 0, 'is_custom': false, 'description': null }"
                               :isFirstRowEmpty="false"
                               :isCopyButton="true"
                               repeaterStyle="margin-top:5px;width:50%"
                               name="productpacks"
                               :title="[__('Pack name'), __('Real weight'), __('Height'), __('Width'), __('Length'), __('Is custom?'), __('Description')]">
                <template #repeat="slotProps">
                    <td :style="slotProps.cellStyle">
                        <FormInputText :style="{ width: '300px' }"
                                       fieldName="productpack_name"
                                       :inRepeater="true"
                                       v-model:field="slotProps.record.productpack_name" />
                    </td>
                    <td :style="slotProps.cellStyle">
                        <FormInputNumber :style="{ width: '80px' }"
                                         fieldName="real_weight"
                                         :listStyle="{ fontSize: '11px' }"
                                         :inputStyle="{ fontSize: '11px' }"
                                         :inRepeater="true"
                                         :minFractionDigits="2"
                                         :maxFractionDigits="2"
                                         v-model:field="slotProps.record.real_weight" />
                    </td>
                    <td :style="slotProps.cellStyle">
                        <FormInputNumber :style="{ width: '80px' }"
                                         fieldName="height"
                                         validation="required"
                                         :listStyle="{ fontSize: '11px' }"
                                         :inputStyle="{ fontSize: '11px' }"
                                         :inRepeater="true"
                                         :minFractionDigits="2"
                                         :maxFractionDigits="2"
                                         v-model:field="slotProps.record.height" />
                    </td>
                    <td :style="slotProps.cellStyle">
                        <FormInputNumber :style="{ width: '80px' }"
                                         fieldName="width"
                                         validation="required"
                                         :listStyle="{ fontSize: '11px' }"
                                         :inputStyle="{ fontSize: '11px' }"
                                         :inRepeater="true"
                                         :minFractionDigits="2"
                                         :maxFractionDigits="2"
                                         v-model:field="slotProps.record.width" />
                    </td>
                    <td :style="slotProps.cellStyle">
                        <FormInputNumber :style="{ width: '80px' }"
                                         fieldName="length"
                                         validation="required"
                                         :listStyle="{ fontSize: '11px' }"
                                         :inputStyle="{ fontSize: '11px' }"
                                         :inRepeater="true"
                                         :minFractionDigits="2"
                                         :maxFractionDigits="2"
                                         v-model:field="slotProps.record.length" />
                    </td>
                    <td :style="slotProps.cellStyle">
                        <FormCheckbox fieldName="is_custom"
                                      :inRepeater="true"
                                      v-model:field="slotProps.record.is_custom" />
                    </td>
                    <td :style="slotProps.cellStyle">
                        <FormInputText :style="{ width: '350px' }"
                                       fieldName="description"
                                       :inRepeater="true"
                                       v-model:field="slotProps.record.description" />
                    </td>
                </template>
            </FormRepeaterTable>
        </div>

        <div class="grid"
             style="margin-top:20px">
            <Button severity="success"
                    style="margin:0 8px"
                    @click="setProductpacks">
                <i class="fa-solid fa-truck"
                   style="margin-right:5px;font-size:18px"></i>
                {{ __('Set packs') }}
            </Button>
            <Button severity="secondary"
                    style="margin:0 8px"
                    @click="cancel">
                <i class="fa-solid fa-ban"
                   style="margin-right:5px;font-size:18px"></i>
                {{ __('Cancel') }}
            </Button>
        </div>
    </v-form>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useForm } from '@inertiajs/vue3'
import Button from 'primevue/button';
import FormCheckbox from '@crud/FormCheckbox.vue'
import FormMultiselect from '@crud/FormMultiselect.vue'
import FormInputText from '@crud/FormInputText.vue'
import { prepareSelect, validation, onChangeService } from '@pages/Helper/crud_utils.js'
import FormInputNumber from '@crud/FormInputNumber.vue'
import FormRepeaterTable from '@crud/FormRepeaterTable.vue'
import { __, screenMessage, getFromRoute } from '@pages/Helper/utils.js'

// -----------------------------------------------------
const props = defineProps({
    formProps: Object,
})

const isProductpacksModalOpen = defineModel('isProductpacksModalOpen')
const productpacks = ref([])
const countries = ref([])

//const parcelsRows = ref([])

// -----------------------------------------------------

const isLoading = ref(false);

let select = ref(null)

// -----------------------------------------------------

onMounted(async () => {
    select.value = await prepareSelect(['countries']);
})

// -----------------------------------------------------

const cancel = () => {
    isProductpacksModalOpen.value = false
}

// -----------------------------------------------------

const setProductpacks = () => {
    const formData = {
        'productpacks': productpacks.value, 'countries': countries.value, selectedRows: props.formProps.selectedRows.map(row => {
            return { 'id': row.id }
        })
    }

    isLoading.value = true;
    if (validation(formData)) {
        useForm(formData).post(route('product-set-product-packs'), {
            preserveState: true,
            preserveScroll: true,
            forceFormData: true,
            onError: (errors) => {
                console.error(errors)
            },
            onSuccess: () => {
                const message = {
                    message: "Packs configuration for selected products was set",
                    title: "Information",
                    type: "info",
                    position: "center",
                    timeout: 5,
                };
                screenMessage(message);
            },
            onFinish: () => {
                props.formProps.clearSelection()
                props.formProps.getLazyData()
            }
        })

        isProductpacksModalOpen.value = false
    }
    isLoading.value = false;
}
// -----------------------------------------------------

</script>

<style lang="scss" scoped></style>
