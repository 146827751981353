<template>
    <v-form :name="props.searchProps.formName"
            @keyup.enter="autoSearch = true">
        <TabView scrollable
                 style="overflow: visible"
                 :pt="{ panelContainer: { class: 'form-tab-panel' } }">
            <TabPanel :header="__('Search condition')"
                      headerClass="form-tab-header">
                <div class="grid">
                    <FormInputText fieldName="product_name"
                                   label="Product name"
                                   size="9"
                                   :isFocus="true"
                                   v-model:field="search.product_name" />
                    <FormMultiselect fieldName="vendor_id"
                                     label="Vendor"
                                     size="3"
                                     showClear
                                     :options="select?.vendors"
                                     :filter="(event) => onFilter(event, 'vendors')"
                                     v-model:field="search.vendor_id" />

                    <FormMultiselect fieldName="productgroup_id"
                                     label="Product group"
                                     size="3"
                                     :options="select?.productgroups"
                                     :filter="(event) => onFilter(event, 'productgroups')"
                                     v-model:field="search.productgroup_id" />

                    <FormMultiselect fieldName="unit_id"
                                     label="Unit"
                                     size="3"
                                     :options="select?.units"
                                     :filter="(event) => onFilter(event, 'units')"
                                     v-model:field="search.unit_id" />

                    <FormMultiselect fieldName="warehouse_id"
                                     label="Warehouse"
                                     size="3"
                                     :options="select?.warehouses"
                                     :filter="(event) => onFilter(event, 'warehouses')"
                                     v-model:field="search.warehouse_id" />

                    <FormMultiselect fieldName="dutycode_id"
                                     label="Duty code"
                                     size="3"
                                     :options="select?.dutycodes"
                                     :filter="(event) => onFilter(event, 'dutycodes')"
                                     v-model:field="search.dutycode_id" />

                    <FormInputText fieldName="ean_code"
                                   label="EAN code"
                                   size="3"
                                   v-model:field="search.ean_code" />

                    <FormInputText fieldName="ean_additional_code"
                                   label="EAN additional code"
                                   size="3"
                                   v-model:field="search.ean_additional_code" />


                    <FormInputText fieldName="product_identity"
                                   label="Product ID"
                                   size="3"
                                   v-model:field="search.product_identity" />

                    <FormInputText fieldName="variant_identity"
                                   label="Variant ID"
                                   size="3"
                                   v-model:field="search.variant_identity" />

                    <FormInputNumber fieldName="length_from"
                                     label="Length from"
                                     size="3"
                                     suffix=" cm"
                                     :allowEmpty="true"
                                     :default="null"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="search.length_from" />

                    <FormInputNumber fieldName="length_to"
                                     label="Length to"
                                     size="3"
                                     suffix=" cm"
                                     :allowEmpty="true"
                                     :default="null"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="search.length_to" />

                    <FormInputNumber fieldName="width_from"
                                     label="Width from"
                                     size="3"
                                     suffix=" cm"
                                     :allowEmpty="true"
                                     :default="null"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="search.width_from" />

                    <FormInputNumber fieldName="width_to"
                                     label="Width to"
                                     size="3"
                                     suffix=" cm"
                                     :allowEmpty="true"
                                     :default="null"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="search.width_to" />

                    <FormInputNumber fieldName="height_from"
                                     label="Height from"
                                     size="3"
                                     suffix=" cm"
                                     :allowEmpty="true"
                                     :default="null"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="search.height_from" />

                    <FormInputNumber fieldName="height_to"
                                     label="Height to"
                                     size="3"
                                     suffix=" cm"
                                     :allowEmpty="true"
                                     :default="null"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="search.height_to" />

                    <FormInputNumber fieldName="real_weight_from"
                                     label="Real weight from"
                                     size="3"
                                     suffix=" kg"
                                     :allowEmpty="true"
                                     :default="null"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="search.real_weight_from" />

                    <FormInputNumber fieldName="real_weight_to"
                                     label="Real weight to"
                                     size="3"
                                     suffix=" kg"
                                     :allowEmpty="true"
                                     :default="null"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="search.real_weight_to" />

                    <FormInputNumber fieldName="net_buy_price_from"
                                     label="Net buy price from"
                                     size="3"
                                     :allowEmpty="true"
                                     :default="null"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="search.net_buy_price_from" />

                    <FormInputNumber fieldName="net_buy_price_to"
                                     label="Net buy price to"
                                     size="3"
                                     :allowEmpty="true"
                                     :default="null"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="search.net_buy_price_to" />

                    <FormInputNumber fieldName="import_costs_from"
                                     label="Import costs from"
                                     size="3"
                                     :allowEmpty="true"
                                     :default="null"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="search.import_costs_from" />

                    <FormInputNumber fieldName="import_costs_to"
                                     label="Import costs to"
                                     size="3"
                                     :allowEmpty="true"
                                     :default="null"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="search.import_costs_to" />

                    <FormInputNumber fieldName="additional_costs_from"
                                     label="Additional costs from"
                                     size="3"
                                     :allowEmpty="true"
                                     :default="null"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="search.additional_costs_from" />

                    <FormInputNumber fieldName="additional_costs_to"
                                     label="Additional costs to"
                                     size="3"
                                     :allowEmpty="true"
                                     :default="null"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="search.additional_costs_to" />

                    <FormMultiselect fieldName="currency_id"
                                     label="Currency"
                                     size="3"
                                     :options="select?.currencies"
                                     v-model:field="search.currency_id" />

                    <FormDropdown fieldName="is_custom"
                                  label="Is custom product?"
                                  size="3"
                                  scrollHeight="100px"
                                  :showClear="true"
                                  :filtering="false"
                                  :options="yesNoOptions"
                                  v-model:field="search.is_custom" />

                    <FormDropdown fieldName="is_configured"
                                  label="Is product configured?"
                                  size="3"
                                  scrollHeight="100px"
                                  :showClear="true"
                                  :filtering="false"
                                  :options="yesNoOptions"
                                  v-model:field="search.is_configured" />
                </div>
            </TabPanel>
        </TabView>


        <div class="grid">
            <SearchButtons :searchProps="searchProps"
                           :search="search"
                           v-model:autoSearch="autoSearch" />
        </div>
    </v-form>
</template>

<script setup>
import { ref } from "vue";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import FormInputText from '@crud/FormInputText.vue'
import FormInputNumber from '@crud/FormInputNumber.vue'
import FormMultiselect from '@crud/FormMultiselect.vue'
import FormDropdown from '@crud/FormDropdown.vue'
import SearchButtons from '@crud/SearchButtons.vue'
import { prepareSearch, yesNoOptions } from '@pages/Helper/crud_utils.js'
import { __ } from '@helper/utils.js'

// -----------------------------------------------------

const props = defineProps({
    searchProps: Object,
})
const autoSearch = ref(false)

const options = ['vendors', 'productgroups', 'units', 'currencies', 'warehouses', 'dutycodes']
const select = ref(null)

let search = ref({})
prepareSearch(search, props, options, select)

// -----------------------------------------------------

</script>

<style lang="scss" scoped></style>
