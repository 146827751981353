<template>
    <Transition v-if="isLoading"
                name="spinner">
        <div id="cover-spin"></div>
    </Transition>

    <v-form :name="props.formProps.formName">
        <TabView scrollable
                 style="overflow: visible"
                 :pt="{ panelContainer: { class: 'form-tab-panel' } }">
            <TabPanel :header="__('Basic data')"
                      headerClass="form-tab-header">
                <div class="grid">
                    <FormInputText fieldName="product_name"
                                   label="Product name"
                                   validation="required"
                                   size="9"
                                   :isFocus="true"
                                   :isFormDisabled="isFormDisabled"
                                   v-model:field="crud.product_name" />

                    <FormDropdown fieldName="carton_id"
                                  label="Courier nomenclature"
                                  size="3"
                                  showClear
                                  :isFormDisabled="isFormDisabled"
                                  :options="select?.cartons"
                                  :filter="(event) => onFilter(event, 'cartons')"
                                  v-model:field="crud.carton_id" />
                </div>
                <div class="grid">
                    <FormDropdown fieldName="vendor_id"
                                  label="Vendor"
                                  size="3"
                                  showClear
                                  validation="required"
                                  :isFormDisabled="isFormDisabled"
                                  :options="select?.vendors"
                                  :filter="(event) => onFilter(event, 'vendors')"
                                  v-model:field="crud.vendor_id" />

                    <FormDropdown fieldName="productgroup_id"
                                  label="Product group"
                                  size="3"
                                  showClear
                                  validation="required"
                                  :isFormDisabled="isFormDisabled"
                                  :options="select?.productgroups"
                                  :filter="(event) => onFilter(event, 'productgroups')"
                                  v-model:field="crud.productgroup_id" />

                    <FormDropdown fieldName="unit_id"
                                  label="Unit"
                                  size="3"
                                  showClear
                                  validation="required"
                                  :isFormDisabled="isFormDisabled"
                                  :options="select?.units"
                                  :filter="(event) => onFilter(event, 'units')"
                                  v-model:field="crud.unit_id" />

                    <FormDropdown fieldName="warehouse_id"
                                  label="Warehouse"
                                  size="3"
                                  showClear
                                  :isFormDisabled="isFormDisabled"
                                  :options="select?.warehouses"
                                  :filter="(event) => onFilter(event, 'warehouses')"
                                  v-model:field="crud.warehouse_id" />
                </div>
                <div class="grid">
                    <FormInputMask fieldName="ean_code"
                                   label="EAN code"
                                   size="2"
                                   validation="required"
                                   mask="9999999999999"
                                   :isFormDisabled="isFormDisabled"
                                   v-model:field="crud.ean_code" />

                    <FormInputMask fieldName="ean_additional_code"
                                   label="EAN additional code"
                                   size="2"
                                   mask="9999999999999"
                                   :isFormDisabled="isFormDisabled"
                                   v-model:field="crud.ean_additional_code" />

                    <FormDropdown fieldName="dutycode_id"
                                  label="Duty code"
                                  size="4"
                                  showClear
                                  :isFormDisabled="isFormDisabled"
                                  :options="select?.dutycodes"
                                  :filter="(event) => onFilter(event, 'dutycodes')"
                                  v-model:field="crud.dutycode_id" />

                    <FormInputText fieldName="product_identity"
                                   label="Product ID"
                                   size="2"
                                   :isFormDisabled="isFormDisabled"
                                   v-model:field="crud.product_identity" />

                    <FormInputText fieldName="variant_identity"
                                   label="Variant ID"
                                   size="2"
                                   :isFormDisabled="isFormDisabled"
                                   v-model:field="crud.variant_identity" />
                </div>
                <div class="grid">
                    <FormInputText fieldName="vendor_product_name"
                                   label="Vendor product name"
                                   size="10"
                                   :isFormDisabled="isFormDisabled"
                                   v-model:field="crud.vendor_product_name" />

                    <FormInputMask fieldName="ean_vendor_code"
                                   label="EAN vendor code"
                                   size="2"
                                   mask="9999999999999"
                                   :isFormDisabled="isFormDisabled"
                                   v-model:field="crud.ean_vendor_code" />
                </div>
                <div class="grid">
                    <FormEditor fieldName="description"
                                label="Description"
                                :editorStyle="{ height: '120px' }"
                                :isFormDisabled="isFormDisabled"
                                v-model:field="crud.description" />

                </div>
            </TabPanel>
            <TabPanel :header="__('Parameters/Quotes')"
                      headerClass="form-tab-header">
                <div class="grid">
                    <FormRadioButton fieldName="price_calculation_type"
                                     label="Method of input price"
                                     size="3"
                                     default="M"
                                     :data="calcTypes"
                                     :isFormDisabled="isFormDisabled"
                                     v-model:field="crud.price_calculation_type" />

                    <FormInputNumber v-if="crud?.price_calculation_type == 'C'"
                                     fieldName="net_unit_price"
                                     label="Net unit price"
                                     size="3"
                                     suffix=" PLN"
                                     :isFormDisabled="isFormDisabled"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="crud.net_unit_price" />
                    <FormInputNumber v-if="crud?.price_calculation_type == 'C'"
                                     fieldName="quantity"
                                     label="Quantity"
                                     size="3"
                                     :isFormDisabled="isFormDisabled"
                                     :minFractionDigits="0"
                                     :maxFractionDigits="0"
                                     v-model:field="crud.quantity" />

                    <FormInputNumber fieldName="net_buy_price"
                                     label="Net buy price"
                                     size="3"
                                     suffix=" PLN"
                                     :isFormDisabled="isFormDisabled || (crud?.price_calculation_type == 'C')"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="crud.net_buy_price" />
                </div>

                <div class="grid">
                    <FormInputNumber fieldName="length"
                                     label="Length"
                                     size="2"
                                     suffix=" cm"
                                     :isFormDisabled="isFormDisabled"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="crud.length" />

                    <FormInputNumber fieldName="width"
                                     label="Width"
                                     size="2"
                                     suffix=" cm"
                                     :isFormDisabled="isFormDisabled"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="crud.width" />

                    <FormInputNumber fieldName="height"
                                     label="Height"
                                     size="2"
                                     suffix=" cm"
                                     :isFormDisabled="isFormDisabled"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="crud.height" />

                    <FormInputNumber fieldName="real_weight"
                                     label="Real weight"
                                     size="3"
                                     suffix=" kg"
                                     :isFormDisabled="isFormDisabled"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="crud.real_weight" />

                    <FormInputSwitch fieldName="is_custom"
                                     label="Is custom product?"
                                     size="3"
                                     :isFormDisabled="isFormDisabled"
                                     v-model:field="crud.is_custom" />
                </div>
                <div class="grid">
                    <FormInputNumber fieldName="import_costs"
                                     label="Net import costs"
                                     size="3"
                                     :isFormDisabled="isFormDisabled"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="crud.import_costs" />

                    <FormInputNumber fieldName="additional_costs"
                                     label="Net additional costs"
                                     size="3"
                                     :isFormDisabled="isFormDisabled"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="crud.additional_costs" />
                    <FormTextarea fieldName="additional_costs_remarks"
                                  label="Additional costs remarks"
                                  size="3"
                                  :rows="2"
                                  :isFormDisabled="isFormDisabled"
                                  v-model:field="crud.additional_costs_remarks" />

                    <FormDropdown fieldName="currency_id"
                                  label="Currency"
                                  size="3"
                                  showClear
                                  validation="required"
                                  :filtering="false"
                                  :isFormDisabled="isFormDisabled"
                                  :options="select?.currencies"
                                  v-model:field="crud.currency_id" />
                </div>
            </TabPanel>

            <TabPanel :header="__('Countries/Packs dimensions')"
                      headerClass="form-tab-header">

                <div class="grid">
                    <FormMultiselect fieldName="countries"
                                     label="Countries"
                                     showClear
                                     :isFormDisabled="isFormDisabled"
                                     :options="select?.countries"
                                     v-model:field="crud.countries" />
                </div>

                <div class="grid">
                    <FormRepeaterTable v-model:record="crud.productpacks"
                                       :keys="{ 'productpack_name': null, 'real_weight': 0, 'height': 0, 'width': 0, 'length': 0, 'is_custom': false, 'is_palette': false, 'is_halfpalette': false, 'is_own_transport': false, 'description': null }"
                                       :disabled="isFormDisabled"
                                       :isFirstRowEmpty="false"
                                       :isCopyButton="true"
                                       headerStyle="text-align:center;border:1px solid #ccc;font-size:13px;padding:8px"
                                       repeaterStyle="margin-top:5px;width:50%"
                                       name="productpacks"
                                       :title="['Pack name', 'Real weight', 'Height', 'Width', 'Length', 'Is custom?', 'Is palette?', 'Is half palette?', 'Is own transport?', 'Description']">
                        <template #repeat="slotProps">
                            <td :style="slotProps.cellStyle">
                                <FormInputText :style="{ width: '350px' }"
                                               fieldName="productpack_name"
                                               :isFormDisabled="isFormDisabled"
                                               :inRepeater="true"
                                               v-model:field="slotProps.record.productpack_name" />
                            </td>
                            <td :style="slotProps.cellStyle">
                                <FormInputNumber :style="{ width: '80px' }"
                                                 fieldName="real_weight"
                                                 :listStyle="{ fontSize: '11px' }"
                                                 :inputStyle="{ fontSize: '11px' }"
                                                 :isFormDisabled="isFormDisabled"
                                                 :inRepeater="true"
                                                 :minFractionDigits="2"
                                                 :maxFractionDigits="2"
                                                 v-model:field="slotProps.record.real_weight" />
                            </td>
                            <td :style="slotProps.cellStyle">
                                <FormInputNumber :style="{ width: '80px' }"
                                                 fieldName="height"
                                                 validation="required"
                                                 :listStyle="{ fontSize: '11px' }"
                                                 :inputStyle="{ fontSize: '11px' }"
                                                 :isFormDisabled="isFormDisabled"
                                                 :inRepeater="true"
                                                 :minFractionDigits="2"
                                                 :maxFractionDigits="2"
                                                 v-model:field="slotProps.record.height" />
                            </td>
                            <td :style="slotProps.cellStyle">
                                <FormInputNumber :style="{ width: '80px' }"
                                                 fieldName="width"
                                                 validation="required"
                                                 :listStyle="{ fontSize: '11px' }"
                                                 :inputStyle="{ fontSize: '11px' }"
                                                 :isFormDisabled="isFormDisabled"
                                                 :inRepeater="true"
                                                 :minFractionDigits="2"
                                                 :maxFractionDigits="2"
                                                 v-model:field="slotProps.record.width" />
                            </td>
                            <td :style="slotProps.cellStyle">
                                <FormInputNumber :style="{ width: '80px' }"
                                                 fieldName="length"
                                                 validation="required"
                                                 :listStyle="{ fontSize: '11px' }"
                                                 :inputStyle="{ fontSize: '11px' }"
                                                 :isFormDisabled="isFormDisabled"
                                                 :inRepeater="true"
                                                 :minFractionDigits="2"
                                                 :maxFractionDigits="2"
                                                 v-model:field="slotProps.record.length" />
                            </td>
                            <td :style="slotProps.cellStyle">
                                <FormCheckbox fieldName="is_custom"
                                              :isFormDisabled="isFormDisabled"
                                              :inRepeater="true"
                                              v-model:field="slotProps.record.is_custom" />
                            </td>
                            <td :style="slotProps.cellStyle">
                                <FormCheckbox fieldName="is_palette"
                                              :isFormDisabled="isFormDisabled"
                                              :inRepeater="true"
                                              v-model:field="slotProps.record.is_palette" />
                            </td>
                            <td :style="slotProps.cellStyle">
                                <FormCheckbox fieldName="is_halfpalette"
                                              :isFormDisabled="isFormDisabled"
                                              :inRepeater="true"
                                              v-model:field="slotProps.record.is_halfpalette" />
                            </td>
                            <td :style="slotProps.cellStyle">
                                <FormCheckbox fieldName="is_own_transport"
                                              :isFormDisabled="isFormDisabled"
                                              :inRepeater="true"
                                              v-model:field="slotProps.record.is_own_transport" />
                            </td>

                            <td :style="slotProps.cellStyle">
                                <FormInputText :style="{ width: '550px' }"
                                               fieldName="description"
                                               :isFormDisabled="isFormDisabled"
                                               :inRepeater="true"
                                               v-model:field="slotProps.record.description" />
                            </td>
                        </template>
                    </FormRepeaterTable>

                </div>
            </TabPanel>

            <TabPanel :header="__('Package configurator')"
                      headerClass="form-tab-header">
                <FormRepeaterTable v-model:record="crud.packages"
                                   :keys="{ 'country_id': null, 'productpack_id': null, 'courier_id': null, 'parcel_id': null, 'volumetric_weight': 0, 'real_weight': 0, 'height': 0, 'liter_capacity': 0 }"
                                   :disabled="isFormDisabled"
                                   :isFirstRowEmpty="false"
                                   :isCopyButton="false"
                                   :isAddButton="false"
                                   :isDeleteButton="false"
                                   :rowStyle="colorRowsByCountry"
                                   :cellStyle="{ border: '1px solid #aaa', textAlign: 'left', padding: '8px', verticalAlign: 'top' }"
                                   repeaterStyle="margin-top:5px;width:50%"
                                   name="packages"
                                   :title="['Country', 'Pack', 'Courier', 'Courier nomenclature', 'Volumetric weight', 'Real weight', 'Height', 'Liter capacity']">
                    <template #repeat="slotProps">
                        <td :style="slotProps.cellStyle">
                            <FormDropdown fieldName="country_id"
                                          showClear
                                          :listStyle="{ fontSize: '11px' }"
                                          :inputStyle="{ width: '120px', fontSize: '11px', color: 'black' }"
                                          :inRepeater="true"
                                          :isFormDisabled="true"
                                          :options="select?.countries"
                                          :change="(event) => getCountryParcels(slotProps.index, event.value)"
                                          :filtering="false"
                                          v-model:field="slotProps.record.country_id" />
                        </td>
                        <td :style="slotProps.cellStyle">
                            <FormDropdown fieldName="productpack_id"
                                          showClear
                                          :listStyle="{ fontSize: '11px' }"
                                          :inputStyle="{ width: '120px', fontSize: '11px', color: 'black' }"
                                          :inRepeater="true"
                                          :isFormDisabled="true"
                                          :options="select?.productpacks"
                                          :filtering="false"
                                          v-model:field="slotProps.record.productpack_id" />
                        </td>

                        <td :style="slotProps.cellStyle">
                            <FormDropdown fieldName="courier_id"
                                          showClear
                                          validation="false"
                                          :listStyle="{ fontSize: '11px' }"
                                          :inputStyle="{ width: '150px', fontSize: '11px', color: 'black' }"
                                          :inRepeater="true"
                                          :isFormDisabled="isFormDisabled"
                                          :options="select?.couriers"
                                          :change="(event) => getCourierParcels(slotProps.index, event.value, slotProps.record)"
                                          :filtering="false"
                                          v-model:field="slotProps.record.courier_id" />
                        </td>
                        <td :style="slotProps.cellStyle">
                            <FormDropdown fieldName="parcel_id"
                                          showClear
                                          validation="false"
                                          :listStyle="{ fontSize: '11px' }"
                                          :inputStyle="{ width: '300px', fontSize: '11px', color: 'black' }"
                                          :inRepeater="true"
                                          :isFormDisabled="isFormDisabled"
                                          :options="getParcelsRow(slotProps.index)"
                                          :filtering="false"
                                          v-model:field="slotProps.record.parcel_id" />
                        </td>
                        <td :style="slotProps.cellStyle">
                            <FormInputNumber :style="{ width: '100px' }"
                                             fieldName="volumetric_weight"
                                             :listStyle="{ fontSize: '11px' }"
                                             :inputStyle="{ fontSize: '11px', color: 'black' }"
                                             :isFormDisabled="true"
                                             :inRepeater="true"
                                             :minFractionDigits="2"
                                             :maxFractionDigits="2"
                                             v-model:field="slotProps.record.volumetric_weight" />
                        </td>
                        <td :style="slotProps.cellStyle">
                            <FormInputNumber :style="{ width: '100px' }"
                                             fieldName="real_weight"
                                             :listStyle="{ fontSize: '11px' }"
                                             :inputStyle="{ fontSize: '11px', color: 'black' }"
                                             :isFormDisabled="true"
                                             :inRepeater="true"
                                             :minFractionDigits="2"
                                             :maxFractionDigits="2"
                                             v-model:field="slotProps.record.real_weight" />
                        </td>
                        <td :style="slotProps.cellStyle">
                            <FormInputNumber :style="{ width: '100px' }"
                                             fieldName="height"
                                             :listStyle="{ fontSize: '11px' }"
                                             :inputStyle="{ fontSize: '11px', color: 'black' }"
                                             :isFormDisabled="true"
                                             :inRepeater="true"
                                             :minFractionDigits="2"
                                             :maxFractionDigits="2"
                                             v-model:field="slotProps.record.height" />
                        </td>
                        <td :style="slotProps.cellStyle">
                            <FormInputNumber :style="{ width: '100px' }"
                                             fieldName="liter_capacity"
                                             :listStyle="{ fontSize: '11px' }"
                                             :inputStyle="{ fontSize: '11px', color: 'black' }"
                                             :isFormDisabled="true"
                                             :inRepeater="true"
                                             :minFractionDigits="2"
                                             :maxFractionDigits="2"
                                             v-model:field="slotProps.record.liter_capacity" />
                        </td>
                    </template>
                </FormRepeaterTable>

            </TabPanel>

            <TabPanel :header="__('Delivery costs')"
                      headerClass="form-tab-header">
                <div class="grid"
                     style="background-color:#ccc">

                    <FormRepeaterTable v-model:record="crud.productcouriercosts"
                                       :keys="{
                                        'country_id': null,
                                        'courier_net_total_pln': 0,
                                        'courier_gross_total_pln': 0,
                                        'courier_net_total_curr': 0,
                                        'courier_gross_total_curr': 0,
                                        'courier_net_logistic_charge_pln': 0,
                                        'courier_gross_logistic_charge_pln': 0,
                                        'courier_net_logistic_charge_curr': 0,
                                        'courier_gross_logistic_charge_curr': 0
                                    }"
                                       :disabled="isFormDisabled"
                                       :isFirstRowEmpty="false"
                                       :isCopyButton="false"
                                       :isAddButton="false"
                                       :isDeleteButton="false"
                                       repeaterStyle="margin-top:5px;width:50%"
                                       name="packages"
                                       :title="[
                                        __('Country'),
                                        __('Courier net total in PLN'),
                                        __('Courier gross total in PLN'),
                                        __('Courier net total in EUR'),
                                        __('Courier gross total in EUR'),
                                        __('Courier net logistic carge in PLN'),
                                        __('Courier gross logistic carge in PLN'),
                                        __('Courier net logistic carge in EUR'),
                                        __('Courier gross logistic carge in EUR')]">
                        <template #repeat="slotProps">
                            <td :style="slotProps.cellStyle">
                                <FormDropdown fieldName="country_id"
                                              showClear
                                              :listStyle="{ fontSize: '11px' }"
                                              :inputStyle="{ width: '120px', fontSize: '11px' }"
                                              :inRepeater="true"
                                              :isFormDisabled="true"
                                              :options="select?.countries"
                                              :filtering="false"
                                              v-model:field="slotProps.record.country_id" />
                            </td>

                            <td :style="slotProps.cellStyle">
                                <FormInputNumber :style="{ width: '100px' }"
                                                 fieldName="courier_net_total_pln"
                                                 :listStyle="{ fontSize: '11px' }"
                                                 :inputStyle="{ fontSize: '11px' }"
                                                 :isFormDisabled="true"
                                                 :inRepeater="true"
                                                 :minFractionDigits="2"
                                                 :maxFractionDigits="2"
                                                 v-model:field="slotProps.record.courier_net_total_pln" />
                            </td>
                            <td :style="slotProps.cellStyle">
                                <FormInputNumber :style="{ width: '100px' }"
                                                 fieldName="courier_gross_total_pln"
                                                 :listStyle="{ fontSize: '11px' }"
                                                 :inputStyle="{ fontSize: '11px' }"
                                                 :isFormDisabled="true"
                                                 :inRepeater="true"
                                                 :minFractionDigits="2"
                                                 :maxFractionDigits="2"
                                                 v-model:field="slotProps.record.courier_gross_total_pln" />
                            </td>
                            <td :style="slotProps.cellStyle">
                                <FormInputNumber :style="{ width: '100px' }"
                                                 fieldName="courier_net_total_curr"
                                                 :listStyle="{ fontSize: '11px' }"
                                                 :inputStyle="{ fontSize: '11px' }"
                                                 :isFormDisabled="true"
                                                 :inRepeater="true"
                                                 :minFractionDigits="2"
                                                 :maxFractionDigits="2"
                                                 v-model:field="slotProps.record.courier_net_total_curr" />
                            </td>
                            <td :style="slotProps.cellStyle">
                                <FormInputNumber :style="{ width: '100px' }"
                                                 fieldName="courier_gross_total_curr"
                                                 :listStyle="{ fontSize: '11px' }"
                                                 :inputStyle="{ fontSize: '11px' }"
                                                 :isFormDisabled="true"
                                                 :inRepeater="true"
                                                 :minFractionDigits="2"
                                                 :maxFractionDigits="2"
                                                 v-model:field="slotProps.record.courier_gross_total_curr" />
                            </td>
                            <td :style="slotProps.cellStyle">
                                <FormInputNumber :style="{ width: '100px' }"
                                                 fieldName="courier_net_logistic_charge_pln"
                                                 :listStyle="{ fontSize: '11px' }"
                                                 :inputStyle="{ fontSize: '11px' }"
                                                 :isFormDisabled="true"
                                                 :inRepeater="true"
                                                 :minFractionDigits="2"
                                                 :maxFractionDigits="2"
                                                 v-model:field="slotProps.record.courier_net_logistic_charge_pln" />
                            </td>
                            <td :style="slotProps.cellStyle">
                                <FormInputNumber :style="{ width: '100px' }"
                                                 fieldName="courier_gross_logistic_charge_pln"
                                                 :listStyle="{ fontSize: '11px' }"
                                                 :inputStyle="{ fontSize: '11px' }"
                                                 :isFormDisabled="true"
                                                 :inRepeater="true"
                                                 :minFractionDigits="2"
                                                 :maxFractionDigits="2"
                                                 v-model:field="slotProps.record.courier_gross_logistic_charge_pln" />
                            </td>
                            <td :style="slotProps.cellStyle">
                                <FormInputNumber :style="{ width: '100px' }"
                                                 fieldName="courier_net_logistic_charge_curr"
                                                 :listStyle="{ fontSize: '11px' }"
                                                 :inputStyle="{ fontSize: '11px' }"
                                                 :isFormDisabled="true"
                                                 :inRepeater="true"
                                                 :minFractionDigits="2"
                                                 :maxFractionDigits="2"
                                                 v-model:field="slotProps.record.courier_net_logistic_charge_curr" />
                            </td>
                            <td :style="slotProps.cellStyle">
                                <FormInputNumber :style="{ width: '100px' }"
                                                 fieldName="courier_gross_logistic_charge_curr"
                                                 :listStyle="{ fontSize: '11px' }"
                                                 :inputStyle="{ fontSize: '11px' }"
                                                 :isFormDisabled="true"
                                                 :inRepeater="true"
                                                 :minFractionDigits="2"
                                                 :maxFractionDigits="2"
                                                 v-model:field="slotProps.record.courier_gross_logistic_charge_curr" />
                            </td>
                        </template>
                    </FormRepeaterTable>
                </div>
            </TabPanel>

            <TabPanel v-if="!tabsDeny('changelog')"
                      :header="__('Changelog')"
                      headerClass="form-tab-header">
                <ChangeLog :log="crud?.log" />
            </TabPanel>
        </TabView>

        <div class="grid">
            <FormButtons :formProps="props.formProps"
                         :sourceForm="crud"
                         :validation="validation"
                         :beforeSave="beforeSave"
                         :buttonsVisibility="buttonsVisibility"
                         v-model:isFormDisabled="isFormDisabled">
                <template #after-in-add-edit="buttonProps">
                    <CustomButton name="recount"
                                  :buttonProps="buttonProps"
                                  :buttonsVisibility="buttonsVisibility"
                                  :click="() => recount(buttonProps.saveData)"
                                  style="margin:0 5px"
                                  severity="info">
                        {{ __('Recount') }}
                    </CustomButton>
                </template>
            </FormButtons>
        </div>
    </v-form>
</template>

<script setup>
import { ref, onMounted, onBeforeMount, watchEffect, nextTick } from 'vue'
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import FormInputSwitch from '@crud/FormInputSwitch.vue'
import FormInputText from '@crud/FormInputText.vue'
import FormInputMask from '@crud/FormInputMask.vue'
import FormInputNumber from '@crud/FormInputNumber.vue'
import FormDropdown from '@crud/FormDropdown.vue'
import FormMultiselect from '@crud/FormMultiselect.vue'
import FormCheckbox from '@crud/FormCheckbox.vue'
import FormEditor from '@crud/FormEditor.vue'
import FormTextarea from '@crud/FormTextarea.vue'
import FormButtons from '@crud/FormButtons.vue'
import CustomButton from '@crud/CustomButton.vue'
import FormRadioButton from '@crud/FormRadioButton.vue'
import FormRepeaterTable from '@crud/FormRepeaterTable.vue'
import ChangeLog from '@crud/ChangeLog.vue'
import { validation, onFilterService, initForm } from '@pages/Helper/crud_utils.js'
import { __, getField, getFromRoute } from '@helper/utils.js'


// -----------------------------------------------------

const props = defineProps({
    formProps: Object,
    buttonsVisibility: Object,
})

const isLoading = ref(true);
const isFormDisabled = ref(false)

const lastCountryId = ref(null)
const countryColors = ref(new Map())

const options = ['vendors', 'productgroups', 'units', 'currencies', 'warehouses', 'cartons', 'dutycodes', 'couriers', 'countries', 'productpacks']
const select = ref(null)
const crud = ref({})

const parcelsRows = ref([])

// -----------------------------------------------------

watchEffect(() => {
    if (crud.value.price_calculation_type == 'C') {
        crud.value.net_buy_price = crud.value.net_unit_price * crud.value.quantity
    }
})

// -----------------------------------------------------

onBeforeMount(async () => {
    await initForm(props, crud, isLoading, options, select, null, null, ['id'], { 'currency_id': await getField('currencies', 'id', "symbol='PLN'") })
    if (crud.value?.packages?.length > 0) {
        crud.value.packages.forEach((record, index) => {
            getCourierParcels(index, record?.courier_id, record)
        })
    }
})

// -----------------------------------------------------

const getParcelsRow = (index) => {
    if (!parcelsRows.value[index]) {
        parcelsRows.value[index] = [];
    }
    return parcelsRows.value[index];
};

// -----------------------------------------------------

const onFilter = async (event, option) => {
    await onFilterService(event, option, select, isLoading)
}

// -----------------------------------------------------

const tabsDeny = (tabName) => {
    return props.formProps?.tabsDeny?.length == 0 || props.formProps?.tabsDeny?.includes(tabName)
}

// -----------------------------------------------------

const getCountryParcels = (index, value) => {
    crud.value.packages[index] = { ...crud.value.packages[index], country_id: value };
    return getParcelsData(index, crud.value?.packages[index]?.country_id, crud.value?.packages[index]?.courier_id);
}

// -----------------------------------------------------

const getCourierParcels = (index, value, record) => {
    crud.value.packages[index] = { ...crud.value.packages[index], courier_id: value };
    calcVolumetricWeight(index, value, record);

    return getParcelsData(index, crud.value?.packages[index]?.country_id, crud.value?.packages[index]?.courier_id);
};

// -----------------------------------------------------

const getParcelsData = async (index, countryId, courierId) => {
    const response = await getFromRoute("product-get-courier-parcels", {
        country_id: countryId ?? 0,
        courier_id: courierId ?? 0,
    });
    const parcels = await response?.parcels ?? null;

    if (parcels && parcels.length > 0) {
        parcelsRows.value[index] = parcels.map((parcel) => {
            return { label: parcel.label, value: parcel?.value ?? null };
        });
    } else {
        parcelsRows.value[index] = [];
        crud.value.packages[index].parcel_id = null;
    }
};

// -----------------------------------------------------

const calcVolumetricWeight = async (index, value, record) => {
    const response = await getFromRoute("product-volumetric-liter", {
        courier_id: value,
        length: crud.value.productpacks.filter((item) => item.id == record?.productpack_id)[0]?.length ?? 0,
        width: crud.value.productpacks.filter((item) => item.id == record?.productpack_id)[0]?.width ?? 0,
        height: crud.value.productpacks.filter((item) => item.id == record?.productpack_id)[0]?.height ?? 0,
        real_weight: crud.value.productpacks.filter((item) => item.id == record?.productpack_id)[0]?.real_weight ?? 0,
    });

    const volumetricWeight = await response?.volumetric_weight ?? null;
    const literCapacity = await response?.liter_capacity ?? null;

    if (volumetricWeight) {
        crud.value.packages[index] = { ...crud.value.packages[index], volumetric_weight: volumetricWeight };
    }
    if (literCapacity) {
        crud.value.packages[index] = { ...crud.value.packages[index], liter_capacity: literCapacity };
    }
};

// -----------------------------------------------------

const colorRowsByCountry = (index, record) => {
    if (!countryColors.value.has(record.country_id)) {
        const color = countryColors.value.size % 2 === 0 ? '#ffffe0' : '#e0e0ff';
        countryColors.value.set(record.country_id, color);
    }
    return { backgroundColor: countryColors.value.get(record.country_id) };
}

// -----------------------------------------------------

const calcTypes = [
    { label: __('manual'), value: 'M' },
    { label: __('calculated'), value: 'C' },
]

// -----------------------------------------------------

const beforeSave = (sourceForm) => {
    if (sourceForm.price_calculation_type == 'M') {
        sourceForm.net_unit_price = 0
        sourceForm.quantity = 0
    }

    return sourceForm;
};

// -----------------------------------------------------

const recount = async (saveData) => {
    await saveData(true);
};

// --------------------------------------------------------------------------------------------------------------------------------------------

</script>

<style lang="scss" scoped></style>
